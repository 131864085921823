import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
import GA4React, { useGA4React } from "ga-4-react";
// import { KeycloakProvider } from "./Contexts/keycloakContext";
const ga4react = new GA4React("G-Z0YCKGDEXV");

(async () => {
  React.icons = icons
  await ga4react.initialize();
  ReactDOM.render(
    <React.StrictMode>
      {/* <KeycloakProvider> */}
        <Provider store={store}>
          <App />
        </Provider>
      {/* </KeycloakProvider> */}
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
